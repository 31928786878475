<template>
  <div class="iv-layout-block">
    <h3 class="iv-fs-16 iv-fc-65 iv-p-b-12 iv-p-t-16">
      <span>{{title}}</span>
      <em v-if="extraInfo" class="iv-fs-16 iv-fc-45">{{extraInfo}}</em>
      <Button v-if="showBtn && !readOnly && !btnList.length" :type="icon" class="iv-layout-block-btn" @click="onClick">{{btnText}}</Button>
      <div class="iv-layout-block-btn" v-if="showBtn && !readOnly && btnList.length" >
        <Button v-for="(item, ind) in btnList" class="iv-m-l-8" :key="ind" :type="item.icon" @click="onClick(item)">{{item.label}}</Button>
      </div>
    </h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    extraInfo: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: '新增'
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'add'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    btnList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    onClick (item) {
      this.$emit('on-click', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.iv-layout-block {
  h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    span {
      border-bottom: 3px solid #FFC757;
      padding-bottom: 14px;
    }
  }
  .iv-layout-block-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
