<template>
<div class="iv-bg-w">
  <div class="iv-news-detail">
    <div class="iv-w">
      <p class="iv-detail-title">{{detail.title}}</p>
      <p class="iv-detail-day"><span>日期：{{getTime(detail.publishDate)}}</span><span>来源：{{detail.infoSource}}</span><span>浏览量：{{detail.browseTotal}}</span></p>
      <p class="iv-detail-conone" v-if="detail.infoType != 5" v-html="detail.infoContent"></p>
      <!-- <LayoutTitle title="制度附件" v-if="regulationFiles.length > 0">
        <p @click="onDownFile(file.fileId)" class="iv-m-t-16 iv-fs-14 iv-pointer iv-hover-color" v-for="file in regulationFiles" :key="file.fileId">{{file.fileName}}</p>
      </LayoutTitle> -->
      <div>
        <template  v-if="detail.infoType == 5 && regulationFileUrl">
          <div class="iv-pdf">
            <pdf ref="pdf" v-for="i in numPages" :key="i" :src="regulationFileUrl" :page="i"></pdf>
          </div>
        </template>
      </div>
      <LayoutTitle title="其他附件" v-if="attachmentFiles.length > 0">
        <p @click="onDownFile(file.fileId)" class="iv-m-t-16 iv-fs-14 iv-pointer iv-hover-color" v-for="file in attachmentFiles" :key="file.fileId">{{file.fileName}}</p>
      </LayoutTitle>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </div>
  </div>
</div>
</template>

<script>
import { timeFormat } from '@/utils'
import LayoutTitle from '@/components/layout/layout-title'
// import pdf from 'vue-pdf'
// import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import pdf from 'vue-pdf-signature'
import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory'
export default {
  data () {
    return {
      detail: {},
      regulationFileUrl: '',
      attachmentFiles: [],
      numPages: 1,
      spinShow: true,
      timer: null // 定时器
    }
  },
  created () {
    this.initTabs()
  },
  methods: {
    getCanvasLoaded () {
      let canvasList = document.getElementsByTagName('canvas')
      for (let i = 0; i < canvasList.length; i++) {
        if (canvasList[i].getContext('2d').getImageData(10,10,50,50).data[0] > 0 && i == (canvasList.length - 1)) {
          this.spinShow = false
          clearInterval()
          this.timer = null
        }
      }
    },
    getNumPages(url) {
      var loadingTask = pdf.createLoadingTask({
        url: url,
        // cMapUrl: '@/assets/cmaps/',
        cMapPacked: true,
        CMapReaderFactory
      })
      loadingTask.promise.then(pdf => {
        this.regulationFileUrl = loadingTask
        this.numPages = pdf.numPages
        this.timer = setInterval(() => {
          this.getCanvasLoaded()
        }, 1000)
      }).catch((err) => {
        console.error('pdf加载失败')
        this.spinShow = false
      })
      for(var key in require.cache) {
        if(key.indexOf('bcmap') >= 0) {
          delete require.cache[key];
        }
      }
    },
    initTabs () {
      this.$ajax({
        url: 'api-qixuan/infoPublish/info/public/get',
        method: 'get',
        data: {
          infoId: this.$route.query.infoId
        }
      }).then(({data}) => {
        this.detail = data.data
        this.detail.infoContent = unescape(this.detail.infoContent)
        if (this.detail.regulationFileId) {
          this.getActivityFiles(this.detail.regulationFileId).then((res) => {
            if (res[0]) {
              this.getNumPages(this.viewUrl(res[0].fileId))
            }
          })
        } else {
          this.spinShow = false
        }
        if (this.detail.attachmentId) {
          this.getActivityFiles(this.detail.attachmentId).then((res) => {
            this.attachmentFiles = res
          })
        }
      })
    },
    getTime (d) {
      return timeFormat(d)
    },
    //获取文件信息
    getActivityFiles (groupId) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-qixuan/file/listFilesByGroupId',
          method: 'GET',
          data: {
            isPage: false,
            groupId: groupId
          }
        }).then(({data}) => {
          resolve(data.data || [])
        })
      })
    },
    onDownFile (id) {
      let url = `api-qixuan/file/downloadFile?fileId=${id}` + '&access_token=' + sessionStorage.getItem('token')
      window.open(url)
    },
    viewUrl (id) {
      let url = `api-qixuan/file/downloadFile?fileId=${id}`
      return url
    }
  },
  components: {
    LayoutTitle,
    pdf
  }
}
</script>
<style>
  .iv-detail-conone img {
    max-width: 100%!important;
    height: auto;
  }
</style>
<style lang="scss" scoped>
.iv-pdf {
  box-shadow: 0 0 8px rgba(98, 98, 98, .05);
  margin-top: 32px;
}
.iv-bg-w {
  padding-top: 70px;
  background-color: white;
  height: 100%;
}
.iv-news-detail {
  background-color: white;
  .iv-w {
    width: 1200px;
    margin:  0 auto;
  }
  .iv-detail-title {
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 44px;
    // font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    width: 1200px;
    text-align: center;
    // height: 44px;
  }
  .iv-detail-day {
    width: 1200px;
    text-align: center;
    margin-top: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    span {
      margin-left: 10px;
    }
  }
  .iv-detail-conone {
    margin-top: 63px;
    font-size: 16px;
    line-height: 36px;
    color: #000000;
  }
  img {
    width: 800px;
    height: 450px;
    margin:46px 0 50px 200px;
  }
  .iv-detail-two {
    font-size: 16px;
    line-height: 36px;
    color: #000000;
    text-indent: 2em;
    margin-bottom: 40px;
  }
  .iv-detail-three {
    font-size: 16px;
    line-height: 36px;
    color: #000000;
    text-indent: 2em;
  }
}
</style>